import React from "react";
import { useSelector } from "react-redux";
import { formatDate } from "../../utils/formateDate";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

const UserProfileExtra = ({
  profile,
  handleClosePasswordModal,
  handleCloseTransPasswordModal,
  moduleStatus,
}) => {
  const { t } = useTranslation();
  const plan = useSelector(
    (state) => state?.dashboard?.appLayout?.moduleStatus?.mlm_plan
  );
  const data = {
    password: "********",
    transactionPassword: "********",
  };
  return (
    <div className="col-lg-9 col-md-12 border-prf-left">
      <div className="profDetailuserDtl">
        <div>
          <h5>{t("email")}</h5>
          {profile?.email ? <p>{profile?.email}</p> : <Skeleton width={200} />}
          {/* email verification need check with BE */}
          {moduleStatus?.kyc_status === 1 &&
            (moduleStatus?.kyc_status === 1 ? (
              <p className="text-success">{t("verified")} </p>
            ) : (
              <p className="text-danger">{t("notVerified")} </p>
            ))}
        </div>
        <div>
          <h5>{t("changePassword")}</h5>
          <p>{data.password}</p>
          <div className="chngPassBtn">
            <button
              type="button"
              className="btn btn-change"
              data-bs-toggle="modal"
              onClick={handleClosePasswordModal}
            >
              {t("changePassword")}
            </button>
          </div>
        </div>
        <div>
          <h5>{t("changeTransactionPassword")}</h5>
          <p>{data.transactionPassword}</p>
          <div className="chngPassBtn">
            <button
              type="button"
              className="btn btn-change"
              data-bs-toggle="modal"
              onClick={handleCloseTransPasswordModal}
            >
              {t("changeTransactionPassword")}
            </button>
          </div>
        </div>
      </div>
      <div className="packageTypesNames">
        <div className="row">
          <div className="col-md-6">
            <div className="packageNames">
              <div className="sponserType">
                <h5>{t("sponsor")}</h5>
                {profile ? <p>{profile?.sponsor}</p> : <Skeleton width={175} />}
              </div>
              <div className="placementType">
                <h5>{t("placement")}</h5>
                {!profile?.father && <Skeleton />}
                <p>{profile?.father}</p>
              </div>
              {plan === "Binary" && (
                <div className="positionType">
                  <h5>{"position"}</h5>
                  {!profile?.position && <Skeleton />}
                  <p>{profile?.position}</p>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              {!!moduleStatus?.product_status && (
                <div className="col-md-6">
                  <div className="PackageDetailProfile">
                    <h5>NFT Quantity</h5>
                    {profile?.package ? (
                      <h6>{profile?.package ?? 1 }</h6>
                    ) : (
                      <Skeleton />  
                    )}
                    {!!moduleStatus?.package_upgrade && (
                      <button type="button" className="btn">
                        {t("upgrade")}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {!!moduleStatus?.subscription_status && (
                <div className="col-md-6">
                  <div className="expiryDetailProfile">
                    <h5>{t("expiry")}</h5>
                    {profile?.productValidity?.productValidityDate ? (

                    <h6>
                      {formatDate(
                        profile?.productValidity?.productValidityDate
                      )}
                    </h6>
                    ) : (
                      <Skeleton/>
                    )}
                    <button type="button" className="btn">
                      {t("renew")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="profileStatusSec">
        <div className="profileStatusBg">
          <div className="profileStatusContDetailSec">
            <div className="profileStatusIconBg">
              <i className="fa-solid fa-user" style={{ color: "#5e28fb" }}></i>
            </div>
            <div className="statusnameCount">
              <h6>{t("personalPV")}</h6>
              {!profile?.pv && <Skeleton />}
              <p>{profile?.pv}</p>
            </div>
          </div>
          <div className="profileStatusContDetailSec">
            <div className="profileStatusIconBgtwo">
              <i
                className="fa-solid fa-user-group"
                style={{ color: "#7e6711" }}
              ></i>
            </div>
            <div className="statusnameCount">
              <h6>{t("groupPV")}</h6>
              {!profile?.gpv && <Skeleton />}
              <p>{profile?.gpv}</p>
            </div>
          </div>
          {plan === "Binary" && (
            <>
              <div className="profileStatusContDetailSec">
                <div className="profileStatusIconBgthree">
                  <i
                    className="fa-solid fa-arrow-left"
                    style={{ color: "#2c628a" }}
                  ></i>
                </div>
                <div className="statusnameCount">
                  <h6>{t("leftCarry")}</h6>
                  {profile?.leftCarry ? (
                    <p>{profile?.leftCarry}</p>

                  ) : (
                    <Skeleton/>
                  )}
                </div>
              </div>
              <div className="profileStatusContDetailSec">
                <div className="profileStatusIconBgfour">
                  <i
                    className="fa-solid fa-arrow-right"
                    style={{ color: "#207b70" }}
                  ></i>
                </div>
                <div className="statusnameCount">
                  <h6>{t("rightCarry")}</h6>
                  {profile?.rightCarry ? (
                    <p>{profile?.rightCarry}</p>

                  )
                   : (
                    <Skeleton/>
                   )}
                </div>
              </div>
            </>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default UserProfileExtra;
