import { Navigate } from 'react-router-dom';
import MainDashboard from '../views/dashboard/MainDashboard';
import AuthLayout from '../Layouts/AuthLayout';
import EwalletLayout from '../views/ewallet/MainEwallet';
import PayoutLayout from '../views/payout/MainPayout';
import RegisterLayout from '../views/register/MainRegister';
import ProfileLayout from '../views/profile/MainProfile';
import GenealogyTree from '../views/tree/GenealogyTree';
import TreeView from '../views/tree/TreeView';
// import EpinLayout from '../views/epin/MainEpin';
// import ShoppingLayout from '../views/shopping/ShoppingLayout';
// import ProductDetailsLayout from '../views/shopping/ProductDetailsLayout';
// import CheckoutLayout from '../views/shopping/CheckoutLayout';
import Faqs from '../components/Tools/Faqs';
import Nft from '../components/Tools/Nft';
import News from '../components/Tools/News';
import DownlineMembers from '../views/tree/DownlineMembers';
import AutoPool from '../views/tree/AutoPool';
import SponserTree from '../views/tree/SponserView';
import ReferralMembers from '../views/tree/ReferralMembers';
import Leads from '../views/Crm/Leads';
import ComingSoon from '../components/Common/ComingSoon';
import BackToOffice from '../components/Auth/BackToOffice';
import RegisterComplete from '../views/register/RegisterComplete';
// import KycDetails from '../components/Profile/KycDetails';
import ReplicaSite from '../views/Tools/ReplicaSite';
import LeadsForm from '../components/Crm/LeadsForm';
import DownloadMaterials from '../views/Tools/DownloadMaterials';
// import ReplicaLayout from '../Layouts/ReplicaLayout';
import ReplicaRegisterLayout from '../views/replica/MainReplicaRegister';
import { ForgotPasswordForm } from '../components/Auth/forgotPassword';
// import Upgrade from '../views/upgrade/Upgrade';
import MainTicket from '../views/ticket/MainTicket';
import CreateTicket from '../views/ticket/CreateTicket';
import TickFaq from '../views/ticket/TickFaq';
import TicketTimeLIne from '../components/tickets/TicketTimeLIne';
import TicketDetail from '../components/tickets/TicketDetail';
import LevelIncome from '../views/earnings/LevelIncome';
import MatrixIncome from '../views/earnings/MatrixIncome';
import PoolIncome from '../views/earnings/PoolIncome';
import Logout from '../views/Logout/Logout';
import TreeDetails from '../views/tree/TreeDetails';
const publicRoutes = [
  {
    path: '/',
    element: <Navigate to="/login" replace />
  },
  {
    path: '/login/:adminUsername?/:username?',
    element: <AuthLayout />
  },
  {
    path: '/oc-login/?:string?:db_prefix?',
    element: <BackToOffice />
  },
  {
    path: '/lcp/:username?/:hash?',
    element: <LeadsForm />
  },
  {
    path: '/replica/:username/',
    // path: '/replica/:username',
    element:<ReplicaRegisterLayout />
  },
  // {
  //   path: '/replica-register',
  //   element: <ReplicaLayout><ReplicaRegisterLayout /></ReplicaLayout>
  // },
  {
    path: '/forgot-password/:hashKey',
    element: <ForgotPasswordForm />
  }
];

const privateRoutes = [
  {
    path: '/matrix-details',
    element: <TreeDetails/>
  },
  {
    path: '/Logout',
    element: <Logout/>
  },
  // {
  //   path: '/replica-register',
  //   element: <ReplicaLayout><ReplicaRegisterLayout /></ReplicaLayout>
  // },
  {
    path: '/level-income',
    element: <LevelIncome/>,
  },
  {
    path: '/club-matrix-income',
    element: <MatrixIncome/>,
  },
  {
    path: '/autopool-income',
    element: <AutoPool/>,
  },
  {
    path: '/pool-income',
    element: <PoolIncome/>,
  },

  {
    path: '/dashboard',
    element: <MainDashboard />,
  },
  {
    path: '/auto-pool-income',
    element: <Navigate to='/autopool-tree' replace />
  },
  {
    path: '/e-wallet',
    element: <EwalletLayout />
  },
  // {
  //   path: '/e-pin',
  //   element: <EpinLayout />
  // },
  {
    path: '/payout',
    element: <PayoutLayout />
  },
  {
    path: '/autopool-tree',
    element: <GenealogyTree />
  },
  {
    path: '/level-tree',
    element: <SponserTree />
  },
  {
    path: '/tree-view',
    element: <TreeView />
  },
  {
    path: '/club-details',
    element: <DownlineMembers />
  },
  {
    path: '/referral-members',
    element: <ReferralMembers />
  },
  {
    path: '/register/:parent?/:position?',
    element: <RegisterLayout />
  },
  {
    path: '/profile',
    element: <ProfileLayout />
  },
  // {
  //   path: '/shopping',
  //   element: <ShoppingLayout />
  // },
  // {
  //   path: '/product-details',
  //   element: <ProductDetailsLayout />
  // },
  // {
  //   path: '/checkout',
  //   element: <CheckoutLayout />
  // },
  {
    path: '/ticket-details/:id?',
    element: <TicketDetail/>
  },
  {
    path: '/create-ticket',
    element: <CreateTicket/>
  },
  {
    path: '/ticket-faq',
    element: <TickFaq/>
  },
  {
    path: '/ticket-timeline/:id?',
    element: <TicketTimeLIne/>
  },
  {
    path: '/faqs',
    element: <Faqs />
  },
  {
    path: '/nft',
    element: <Nft />
  },
  {
    path: '/news/:newsId?',
    element: <News />
  },
  {
    path: '/leads',
    element: <Leads />
  },
  {
    path: '/settings',
    element: <ComingSoon />
  },
  {
    path: '/downloads',
    element: <ComingSoon />
  },
  {
    path: '/mail-box',
    element: <ComingSoon />
  },
  {
    path: '/support-center',
    element:<MainTicket/>
  },
  {
    path: '/crm',
    element: <ComingSoon />
  },
  {
    path: '/download-materials',
    element: <DownloadMaterials />
  },
  {
    path: '/replica-site',
    element: <ReplicaSite />
  },
  {
    path: '/promotion-tools',
    element: <ComingSoon />
  },
  {
    path: '/add-lead',
    element: <ComingSoon />
  },
  {
    path: '/view-lead',
    element: <ComingSoon />
  },
  {
    path: '/graph',
    element: <ComingSoon />
  },
  {
    path: '/registration-complete/:username?',
    element: <RegisterComplete />
  },
  // {
  //   path: '/kyc-details',
  //   element: <KycDetails />
  // },
  // {
  //   path: '/upgrade',
  //   element: <Upgrade />
  // }

]

export { privateRoutes, publicRoutes }
