import { useParams } from "react-router";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { TicketService } from "../../services/ticket/ticket.js";
import { useState, useRef } from "react";
import { toast } from "react-toastify";
import { BASE_URL } from "../../config/config.js";
// import API from '../../api/api'
import axios from "axios";
import { DEFAULT_KEY } from "../../config/config.js";

function TicketDetail() {
  const params = useParams();
  const queryClient = useQueryClient();
  const [newMessage, setNewMessage] = useState("");
  const attachRef = useRef();
  const [file, setFile] = useState(null);
  let { id } = params;

  const customAxios = axios.create({
    baseURL: BASE_URL,
  });

  customAxios.defaults.headers.common["api-key"] = DEFAULT_KEY;
  customAxios.defaults.headers.common["access-token"] =
    localStorage.getItem("access-token") || "";
  customAxios.defaults.headers.common["Content-Type"] = "multipart/form-data";

  const { data, isLoading, isSuccess } = useQuery({
    queryKey: ["ticket-details", id],
    queryFn: () => TicketService.getTicket(id),
    enabled: !!id,
  });
  console.log(file, "attachRef");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleSendMessage = () => {

    const formData = new FormData();
    formData.append("file", file);
    formData.append("message",newMessage)

    if (newMessage) {
      
      console.log(data, "data");
      customAxios.post(`save_ticket/${id}`, formData).then((res) => {
        if (res.status) {
          queryClient.invalidateQueries({ queryKey: ["ticket-details"] });
          toast.success("Message sent");
          setNewMessage("");
        }
      });
    }
  };

  return (
    <>
      <div className="page_head_top">Ticket Details</div>
      {isLoading ? (
        <>
          <p>Loadig.....</p>
        </>
      ) : (
        <div class="support_cnt_box">
          <div class="row">
            <div class="col-md-6">
              <div class="support_chat_left_box">
                <div class="bootstrap snippets bootdey">
                  <div class="tile tile-alt" id="messages-main">
                    <div class="ms-body">
                      <div class="action-header clearfix">
                        <div class="visible-xs" id="ms-menu-trigger">
                          <i class="fa fa-bars"></i>
                        </div>

                        <div class="pull-left avatar_top">
                          <img
                            src={
                              data?.value?.details?.profile ??
                              "https://bootdey.com/img/Content/avatar/avatar2.png"
                            }
                            alt=""
                            class="img-avatar m-r-10"
                          />
                          <div class="lv-avatar pull-left"></div>
                          <span>{data.value.details.userFullName}</span>
                        </div>
                      </div>

                      <div class="message_body_area">
                        {data.value.ticket_replies.map((i) =>
                          i.user_id !== data?.value?.details?.user_id ? (
                            <div className="message-feed media">
                              <div className="pull-left">
                                <img
                                  src={
                                    i.profile_pic ??
                                    "https://bootdey.com/img/Content/avatar/avatar1.png"
                                  }
                                  alt=""
                                  className="img-avatar"
                                />
                              </div>
                              <div className="media-body">
                                <div className="mf-content">{i.message}</div>
                                <small className="mf-date">
                                  <i className="fa fa-clock-o"></i> {i.date}
                                </small>
                              </div>
                            </div>
                          ) : (
                            <div className="message-feed right">
                              <div className="pull-right">
                                <img
                                  src={
                                    i.profile_pic ??
                                    "https://bootdey.com/img/Content/avatar/avatar2.png"
                                  }
                                  alt=""
                                  className="img-avatar"
                                />
                              </div>
                              <div className="media-body">
                                <div className="mf-content">{i.message}</div>
                                <small className="mf-date">
                                  <i className="fa fa-clock-o"></i> {i.date}
                                </small>
                              </div>
                            </div>
                          )
                        )}
                      </div>

                      <div className="media-body">
                        <div className="col-md-12">
                          <div className="form-group mb-2">
                            
                            <input
                              type="file"
                              placeholder=""
                              className="form-control"
                              name="fileUpload"
                              onChange={handleFileChange}
                            />
                            <label htmlFor="fileUpload">  {" "}File Upload</label>
                            {/* {fileResponse?.success && (
                            <div style={{ color: "green" }}>
                              {t(fileResponse?.success)}
                            </div>
                          )}
                          {fileResponse?.error && (
                            <div style={{ color: "red" }}>
                              {t(fileResponse?.error)}
                            </div>
                          )} */}
                          </div>
                        </div>
                      </div>
                      <div class="msb-reply">
                        <textarea
                          placeholder="What's on your mind..."
                          value={newMessage}
                          onChange={(e) => setNewMessage(e.target.value)}
                        ></textarea>
                        <button onClick={handleSendMessage}>
                          <i className="fa-regular fa-paper-plane"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <table class="table border">
                <tr>
                  <th>Tikcet Id</th>
                  <td>:</td>
                  <td>#{data.value.details.ticket_id}</td>
                </tr>
                <tr>
                  <th>Tikcet Status</th>
                  <td>:</td>
                  <td>{data.value.details.status}</td>
                </tr>
                <tr>
                  <th>Created On</th>
                  <td>:</td>
                  <td>{data.value.details.created_date}</td>
                </tr>
                <tr>
                  <th>Update Date</th>
                  <td>:</td>
                  <td>{data.value.details.updated_date}</td>
                </tr>
                <tr>
                  <th>Category</th>
                  <td>:</td>
                  <td>{data.value.details.category}</td>
                </tr>
                <tr>
                  <th>Priority</th>
                  <td>:</td>
                  <td>{data.value.details.priority}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TicketDetail;

{
  /* <div class="msb-reply">
                                  <textarea placeholder="What's on your mind..."></textarea>
                                  <button onClick={()=>{
                                    queryClient.invalidateQueries({ queryKey: ['ticket-details'] })
                                  }}><i class="fa-regular fa-paper-plane"></i></button>
                              </div> */
}
