import React, { useState } from "react";
import MatrixMembersTable from "../../components/Tree/MatrixMembersTable";
import { NavLink } from "react-router-dom";
// import SubmitButton from "./Common/buttons/SubmitButton";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { ApiHook } from "../../hooks/apiHook";

function MatrixIncome() {
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;
  const [username, setUsername] = useState("");
  const [selectedMenu, setSelectedMenu] = useState(false);
  const [searchUsername, setSearchUsername] = useState("");

  if (location.pathname === "level-tree") {
    ApiHook.CallSponsorTreeList("", "", searchUsername);
  } else if (location.pathname === "/genealogy-tree") {
    ApiHook.CallGenealogyTreeList("", "", searchUsername);
  } else {
    //  Do nothing
  }
  const trimmedPathname = pathname.replace(/^\//, "");
  console.log("trimmedPathname", trimmedPathname);
  const plan = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus?.mlm_plan
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setUsername(value);
  };
  const handleSearch = () => {
    setSearchUsername(username);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // If Enter key is pressed, trigger the search
      handleSearch();
    }
  };
  const handleSideMenuToggle = () => {
    setSelectedMenu(!selectedMenu);
  };

  return (
    <>
      <div className="page_head_top">
        Matrix Income
        <div className="right_btn_mob_toggle" onClick={handleSideMenuToggle}>
          <i className="fa fa-bars"></i>
        </div>
      </div>
      <div
        className={`tree_view_top_filter_bar mt-2 hide_show_mobile ${selectedMenu ? "show_mn" : ""
          }`}
      >
        <div className="row justify-content-between">
          <div
            className={`col-md-8 hide_show_mobile ${selectedMenu ? "show_mn" : ""
              }`}
          >
            <NavLink
              to="/club-matrix-income"
              className={({ isActive }) =>
                isActive ? "btn_ewallt_page active" : "btn_ewallt_page"
              }
            >
              Matrix Referrals
            </NavLink>
            {plan !== "Unilevel" && (
              <NavLink
                // to={"/referral-members"}
                to={"/matrix-details"}
                className={({ isActive }) =>
                  isActive ? "btn_ewallt_page active" : "btn_ewallt_page"
                }
              >
                {t("Matrix Details")}
              </NavLink>
            )}
            <NavLink
              to={"/club-details"}
              className={({ isActive }) =>
                isActive ? "btn_ewallt_page active" : "btn_ewallt_page"
              }
            >
              {t("Club Details")}
            </NavLink>
          </div>
          {(trimmedPathname === "genealogy-tree" ||
            trimmedPathname === "sponsor-tree") && (
              <div className="col-md-4 mob_filter_right">
                <div className="right_search_div d-flex gap-1">
                  <Form.Group>
                    <Form.Control
                      id="Search"
                      type="text"
                      placeholder="Search"
                      onChange={(e) => handleChange(e)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      value={username}
                      required
                    />
                  </Form.Group>
                </div>
              </div>
            )}
        </div>
      </div>

      <MatrixMembersTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}

export default MatrixIncome;
