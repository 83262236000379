import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink ,Link} from "react-router-dom";
import { DOMAIN_URL } from "../../config/config";

function UserDropdown({ userProfile ,replicaLink}) {
  
  const { t } = useTranslation();
  
  const progressBarRef = useRef(null);
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );
  

  useEffect(() => {
    const strokeDashOffsetValue =
      100 - (userProfile?.productValidity?.packageValidityPercentage ?? 100);
    progressBarRef.current.style.strokeDashoffset = strokeDashOffsetValue;
  }, []);

  return (
    <>
      <aside className="left_sidebar"></aside>
      <div className="rightSide_top_user_dropdown">
        <div className="rightSide_top_user_dropdown_avatar_sec">
          <div className="rightSide_top_user_dropdown_avatar">
            <img src={userProfile?.image ? userProfile?.image : "/images/user-profile.png"} alt="" />
            {/* {!!moduleStatus?.kyc_status ? (
               <div className="kyc_vrfd">
               {userProfile?.userProfile?.kycStatus ? (
                    <img src="/images/kyc_vrfd.svg" alt="" />
                ) : (
                    <img src="/images/kyc_not_vrfd.png" alt="" />
                )}
              </div>
             ) : (
              <div className="kyc_vrfd">
                <img src="/images/kyc_vrfd.svg" alt="" />
               </div>
            )} */}
            <svg
              className="profile_progress"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-1 -1 34 34"
            >
              <circle
                cx="16"
                cy="16"
                r="15.9155"
                className="progress-bar__background"
              />
              <circle
                cx="16"
                cy="16"
                r="15.9155"
                className="progress-bar__progress js-progress-bar"
                ref={progressBarRef}
              />
            </svg>
          </div>
        </div>
        <div className="rightSide_top_user_dropdown_nameBOx">
          <div className="rightSide_top_user_dropdown_name">
            {userProfile?.fullname}
          </div>
          <div className="rightSide_top_user_dropdown_id">
            {userProfile?.username}
          </div>
          {!!moduleStatus?.product_status && (
            <div className="rightSide_top_user_dropdown_package">
            NFT Quantity : {userProfile?.quntity ?? 0 }
            </div>
          )}
        </div>
      </div>
      <div className="top_right_personal_dtl_box_sec">
        {/* <div className="top_right_personal_dtl_box border-sprt">
          <span>{t('personalPV')}</span>
          <strong>{userProfile?.personalPv ?? 0}</strong>
        </div>
        <div className="top_right_personal_dtl_box">
          <span>{t('groupPV')}</span>
          <strong>{userProfile?.groupPv ?? 0}</strong>
        </div> */}
      </div>
      <div className="top_right_personal_dtl_box_sec">
        <div className="top_right_personal_dtl_box text-center">
        <a href={replicaLink?.link} target="_blank">{replicaLink?.link}</a>

        {/* <NavLink to={replicaLink?.link}>
                   {newURL}
                   </NavLink> */}
          
          {/* <span>{`${DOMAIN_URL}/replica/${userProfile?.username??''}`.toLowerCase()}</span> */}
          {/* <strong>{userProfile?.sponsorName}</strong> */}
          {/* <strong>http://127.0.0.1:3000/replica/test01/891088c0b9ca44a73daaf3b22ae8af082685f2b1d1c3874b9989afc7faa9c0a6</strong> */}
        </div>
      </div>
      <div className="top_profile_upgrade_renew_btn_sec">
      {!!moduleStatus?.package_upgrade && (
          <NavLink
            to="/upgrade"
            className="top_profile_upgrade_renew_btn_1"
            activeClassName="active"
          >
            {t("upgrade")}
          </NavLink>
        )}
        {!!(moduleStatus?.subscription_status) && (
          <button className="top_profile_upgrade_renew_btn_1">{t('Renew')}</button>
        )}
      </div>
    </>
  );
}

export default UserDropdown;
