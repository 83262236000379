import React, { useEffect } from 'react'
import { ApiHook } from '../../hooks/apiHook';
function Logout() {
    const logoutMutation = ApiHook.CallLogout();
    useEffect(()=>{
        logoutMutation.mutate();
    },[])
  return (
   <>
   </>
  )
}


export default Logout