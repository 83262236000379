import React, { useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
// import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";
import GenealogyTreeContent from "../../components/Tree/genealogy-tree/GenealogyTreeContent";
import { ApiHook } from "../../hooks/apiHook";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useTranslation } from "react-i18next";

const GenealogyTree = () => {
  const [selectedUserId, setSelectedUserId] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [doubleUser, setDoubleUser] = useState("");
  ApiHook.CallGenealogyTreeList("", "", searchUsername);
  
  const { t } = useTranslation();
  const [selectedMenu, setSelectedMenu] = useState(false);

  const plan = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus?.mlm_plan
  );

  const handleSideMenuToggle = () => {
    setSelectedMenu(!selectedMenu);
  };


  return (
    <>
      {/* <TreeViewNavbar
        menu={"genealogyTree"}
        searchUsername={searchUsername}
        setSearchUsername={setSearchUsername}
      /> */}

      <div className="page_head_top">
        AutoPool Earning
        <div className="right_btn_mob_toggle" onClick={handleSideMenuToggle}>
          <i className="fa fa-bars"></i>
        </div>
      </div>
      <div
        className={`tree_view_top_filter_bar mt-2 hide_show_mobile ${
          selectedMenu ? "show_mn" : ""
        }`}
      >
        <div className="row justify-content-between">
          <div
            className={`col-md-8 hide_show_mobile ${
              selectedMenu ? "show_mn" : ""
            }`}
          >
            {plan !== "Unilevel" && (
              <NavLink
                to="/autopool-tree"
                className={({ isActive }) =>
                  isActive ? "btn_ewallt_page active" : "btn_ewallt_page"
                }
              >
               Autopool Tree
              </NavLink>
            )}
            <NavLink
              to="/autopool-income"
              className={({ isActive }) =>
                isActive ? "btn_ewallt_page active" : "btn_ewallt_page"
              }
            >
              AutoPool Referrals
            </NavLink>

            
          </div>
        </div>
      </div>

      <GenealogyTreeContent
        selectedUserId={selectedUserId}
        setSelectedUserId={setSelectedUserId}
        doubleUser={doubleUser}
        setDoubleUser={setDoubleUser}
        searchUsername={searchUsername}
        setSearchUsername={setSearchUsername}
      />
    </>
  );
};

export default GenealogyTree;
