import { useState, useRef } from "react";
import SubmitButton from "../buttons/SubmitButton";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../../hooks/apiHook";

const TableFilter = ({ setFormData, formData }) => {
  const [selectedValue, setSelectedValue] = useState(formData);

  const { t } = useTranslation();

  const handleTabChange = (e) => {
    if (e.target.id === "searchButton") {
      setFormData(selectedValue);
    } else if (e.target.id === "resetButton") {
      setFormData({
        ticketId: "",
        category: "",
        priority: "",
        status: "",
      });
      setSelectedValue({
        ticketId: "",
        category: "",
        priority: "",
        status: "",
      })
    }
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setSelectedValue({ ...selectedValue, [name]: value });
  };

  const { data, error, isLoading } = ApiHook.CallFilters();


  return (
    <>
      <div className="filter_Section">
        <div className="row justify-content-between">
          <div className="col-md-8">
            <div className="right_search_div d-flex gap-1">
              <div className="row align-items-center">
                <div className="right_search_div">
                  <label for="">Ticket Id</label>
                  <input
                    type="text"
                    name="ticketId"
                    className="form-control"
                    placeholder="Ticket Id"
                    value={selectedValue.ticketId}
                    onChange={handleSelectChange}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="right_search_div">
                  <label for="">Category</label>
                  <select
                    name="category"
                    className="form-control"
                    id="category"
                    value={selectedValue.category}
                    onChange={handleSelectChange}
                  >
                    {data?.category.map((i) => (
                      <option value={i.id}>{i.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <div className="right_search_div">
                  <label for="">Priority</label>
                  <select
                    name="priority"
                    className="form-control"
                    id="priority"
                    value={selectedValue.priority}
                    onChange={handleSelectChange}
                  >
                    {data?.priority.map((i) => (
                      <option value={i.id}>{i.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <div className="right_search_div">
                  <label for="">Status</label>
                  <select
                    name="status"
                    className="form-control"
                    id="status"
                    value={selectedValue.status}
                    onChange={handleSelectChange}
                  >
                    {data?.status.map((i) => (
                      <option value={i.id}>{i.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-3 mt-4">
                <div className="right_search_div d-flex gap-1">
                  <SubmitButton
                    isSubmitting=""
                    text="Search"
                    className="btn btn-primary"
                    click={handleTabChange}
                    id="searchButton"
                  />
                  <SubmitButton
                    isSubmitting=""
                    text="Reset"
                    className="btn btn-secondary"
                    click={handleTabChange}
                    id="resetButton"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableFilter;
