import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { TicketService } from "../../services/ticket/ticket.js";
import { useState } from "react";
import { ApiHook } from "../../hooks/apiHook.js";

function TicketTimeLIne() {
  const params = useParams();
  
  // const queryClient = useQueryClient();
  let { id } = params;
  const data = ApiHook.CallTicketTimeline(id);
  const reversedData = data?.slice().reverse();
  console.log(data,"akhila");

  return (
    <>
      <div class="support_cnt_box">
        <section class="timeline">
          <h4>
            <Link to="/support-center" class="back_btn ">
              <i class="fa-solid fa-arrow-left"></i>
            </Link>{" "}
            Ticket : # {id}
          </h4>
          <ul>
            {reversedData?.map((item, index) => (
              <li key={index}>
                <div>
                  <time>{item.date}</time>
                  <h5>{item.activity}</h5>
                  <p>{item.comments}</p>
                  <p>{item.message}</p>
                </div>
              </li>
            ))}
          </ul>
         
        </section>
      </div>
    </>
  );
}

export default TicketTimeLIne;
