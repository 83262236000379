import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

function Selector({ pathname }) {
   
  const { t } = useTranslation();
  switch (pathname) {
    case "support-center":
      return (
        <>
          <div className="page_head_top">{t("Ticket")}</div>
          <div class="tree_view_top_filter_bar mt-2 hide_show_mobile ">
            <div class="row justify-content-between">
              <div class="col-md-8 hide_show_mobile">
                <Link to="/support-center" className="btn_ewallt_page active ">My Ticket</Link>
                <Link to="/create-ticket" className="btn_ewallt_page">Create Ticket</Link>
                {/* <Link to="/ticket-faq" className="btn_ewallt_page">FAQs</Link> */}
              </div>
            </div>
          </div>
        </>
      );
    case "create-ticket":
      return (
        <>
        <div className="page_head_top">{t("Create Ticket")}</div>
        <div class="tree_view_top_filter_bar mt-2 hide_show_mobile ">
          <div class="row justify-content-between">
            <div class="col-md-8 hide_show_mobile">
              <Link to="/support-center" className="btn_ewallt_page">My Ticket</Link>
              <Link to="/create-ticket" className="btn_ewallt_page active">Create Ticket</Link>
              {/* <Link to="/ticket-faq" className="btn_ewallt_page">FAQs</Link> */}
            </div>
          </div>
        </div>
      </>
      );
    case 'ticket-faq':
        return(
            <>
            <div className="page_head_top">{t("FAQs")}</div>
            <div class="tree_view_top_filter_bar mt-2 hide_show_mobile ">
              <div class="row justify-content-between">
                <div class="col-md-8 hide_show_mobile">
                  <Link to="/support-center" className="btn_ewallt_page">My Ticket</Link>
                  <Link to="/create-ticket" className="btn_ewallt_page">Create Ticket</Link>
                  <Link to="/ticket-faq" className="btn_ewallt_page active">FAQs</Link>
                </div>
              </div>
            </div>
          </>
        );
  }
}

export default Selector;
