import { useState, useRef } from "react";
import LabelledButton from "../buttons/LabelledButton";
import SubmitButton from "../buttons/SubmitButton";
import { useTranslation } from "react-i18next";

const PayoutTableFilter = ({ setActiveTab, activeTab, data }) => {
  const [selectedValue, setSelectedValue] = useState(activeTab);

  const { t } = useTranslation();

  const handleTabChange = (e) => {
    const selectedValue = document.getElementById("category").value;
    if (e.target.id === "searchButton") {
      setActiveTab(selectedValue);
    } else if (e.target.id === "resetButton") {
      setActiveTab("paid");
    }
  };
  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };

  return (
    <>
      <div className="filter_Section">
        <div className="row justify-content-between">
          <div className="col-md-4">
            <div className="right_search_div d-flex gap-1">
              <select
                name="category"
                className="form-control"
                id="category"
                value={selectedValue}
                onChange={handleSelectChange}
              >
                <option value="paid">{t("paid").toLocaleUpperCase()}</option>
                <option value="requested">
                  {t("requested").toLocaleUpperCase()}
                </option>
                <option value="approved">
                  {t("approved").toLocaleUpperCase()}
                </option>
                <option value="rejected">
                  {t("rejected").toLocaleUpperCase()}
                </option>
              </select>
              <SubmitButton
                isSubmitting=""
                text="Search"
                className="btn btn-primary"
                click={handleTabChange}
                id="searchButton"
              />
              <SubmitButton
                isSubmitting=""
                text="Reset"
                className="btn btn-secondary"
                click={handleTabChange}
                id="resetButton"
              />
            </div>
          </div>
          <div className="col-md-4 text-end mob_filter_right">
            <LabelledButton
              className="fa fa-file-excel"
              text=" Excel"
              data={data}
              fileName={activeTab}
            />
            <LabelledButton
              className="fa fa-file-excel"
              text=" CSV"
              data={data}
              fileName={activeTab}
            />
            <LabelledButton
              className="fa fa-print"
              text=" Print"
              data={data}
              fileName={activeTab}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PayoutTableFilter;
