import { Hourglass } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import Selector from "./Selector";
import { ApiHook } from "../../hooks/apiHook";
import TicketTable from "../../components/tickets/TicketTable";
import { React, useState } from "react";

const MainTicket = () => {
  const [pageDetails, setPageDetails] = useState({
    itemsPerPage: 10,
    currentPage: 1,
  });
  const [formData, setFormData] = useState({
    ticketId: "",
    category: "",
    priority: "",
    status: "",
  });

  const location = useLocation();

  const { data, error, isLoading } = ApiHook.CallTickets(
    pageDetails,
    formData
  );

  console.log(data, "formData");
  return (
    <>
      <Selector pathname={location.pathname.replace(/^\/+/g, "")} />
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            top: "50vh",
            left: "50vw",
          }}
        >
          <Hourglass
            visible={true}
            height="80"
            width="80"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["#306cce", "#72a1ed"]}
          />
        </div>
      ) : (
        <TicketTable
          data={data}
          setFormData={setFormData}
          setPageDetails={setPageDetails}
          pageDetails={pageDetails}
          formData={formData}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default MainTicket;
