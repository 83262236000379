import { data } from "jquery";
import API from "../../api/api";

const callApi = async (endpoint) => {
  try {
    const response = await API.get(endpoint);

    if (response.status === 200) {
      return response?.data;
    } else {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const TicketService = {
  callfilters: async () => {
    const response = await callApi("ticket/filters");
    let data = {};
    console.log(response, "=================");
    return response || data;
  },

  getTicket: async (ticket_id) => {
    console.log("here", ticket_id);
    const res = await API.get(`ticket_details/${ticket_id}`);
    return res.data;
  },
  getTimeline: async (ticket_id) => {
    console.log("here", ticket_id);
    const res = await callApi(`ticket_time_line/${ticket_id}`);
    return res.data;
  },
  getTickets: async (currentPage, itemsPerPage, formData) => {
    console.log("currentPage, itemsPerPage, formData,",currentPage, itemsPerPage, formData)
    const res = await callApi(
      `tickets?ticketId=${formData.ticketId}&category=${formData.category}&priority=${formData.priority}&status=${formData.status}&page=${currentPage}&perpage=${itemsPerPage}`
    );
    console.log(res, "here");
    return res.data;
  },
};
