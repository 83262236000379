
import { useTranslation } from "react-i18next";
import { useState } from "react";
import TableContent from "../Common/table/TableContent";
import TablePagination from "../Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
function LevelTable(props) {
  const data=ApiHook.CallLevelDetails()
  console.log(data?.data?.result)
    const { t } = useTranslation();
    const headers = [
        t("LEVEL 1"),
        t("LEVEL 2"),
        t("LEVEL 3"),
        t("LEVEL 4"),
        t("LEVEL 5"),
        t("LEVEL 6"),
        t("LEVEL 7"),
        t("LEVEL 8"),
        t("LEVEL 9"),
      ];
     
  return (
    <>
        <div className="ewallet_table_section">
        <div className="ewallet_table_section_cnt">
          <div className="table-responsive min-hieght-table">
            <TableContent
              headers={headers}
               data={data?.data?.result}
              type="matrix-detail"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default LevelTable