import {TicketService} from "../../services/ticket/ticket";

export const TicketActions = {
    getTickets: async (pageDetails, formData) => {
    try {
      const response = await TicketService.getTickets(pageDetails.currentPage, pageDetails.itemsPerPage, formData);
      return response;
    } catch (error) {
      return error.message;
    }
  },
};
