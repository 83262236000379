import React from "react";
import TableTickets from "./TableTickets";
import TableFilter from "../Common/table/TableFilter";
import { getLastPage } from "../../utils/getLastPage";

const TicketTable = (props) => {
  const lastPage = getLastPage(props.pageDetails.itemsPerPage, props?.data?.totalCount);
  

  return (
    <div className="ewallet_table_section">
      <div className="ewallet_table_section_cnt">
        <TableFilter
          setFormData={props.setFormData}
          formData={props.formData}
        />
        <div className="table-responsive min-hieght-table">
          <TableTickets
            data={props?.data}
            startPage={1}
            totalPages={lastPage}
            pageDetails={props.pageDetails}
            setPageDetails={props.setPageDetails}
            isLoading={props.isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketTable;
