import React from 'react'
import TreeViewNavbar from '../../components/Tree/TreeViewNavbar'
import LevelTable from '../../components/Tree/LevelTable'
function TreeDetails() {
  return (
    <>
         <TreeViewNavbar menu={"Matrix Details"} />
        <LevelTable/>
    </>
  )
}

export default TreeDetails