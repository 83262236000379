import React from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../utils/formateDate";

import { useSelector } from "react-redux";

import CurrencyConverter from "../../Currency/CurrencyConverter";
// import { TableSkeleton } from "./table/TableSkeleton";

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const userSelectedCurrency = useSelector(
    (state) => state?.user?.selectedCurrency
  );
  const selectedLanguage = useSelector(
    (state) => state?.user?.selectedLanguage
  );
  const user = props.preview?.userData;
  const companyData = props.preview?.companyData;
  const packageData = props.preview?.productData;
  const welcomeContent = props.preview?.welcomeLetter;
  const date = props.preview?.date;
  const selectedLanguageId = selectedLanguage?.id;
  const selectedWelcomeContent = welcomeContent?.find(item => item.id === selectedLanguageId);
  const welcomeContentHtml = selectedWelcomeContent?.content || "";
  {console.log(props.preview)}

  return (
    <div ref={ref}>
      <div className="page_head_top" style={{color:"green"}}>{t("registrationComplete")}</div>
      <div className="ewallet_table_section">
        <div className="ewallet_table_section_cnt">
          
          <div className="table-responsive min-hieght-table">
            <div
              className="cmpnyAddressname"
              style={{ textAlign: "end", padding: "10px" }}
            >
              <p style={{ marginBottom: "5px" }}>{companyData?.name}</p>
              <p style={{ marginBottom: "0" }}>{companyData?.address}</p>
            </div>
            <table className="striped">
              <tbody>
                <tr>
                  <td>{t("username")}</td>
                  <td>{user?.username}</td>
                </tr>
                <tr>
                  <td>{t("fullName")}</td>
                  <td>{user?.fullName}</td>
                </tr>
                <tr>
                  <td>{t("sponsor")}</td>
                  <td>{user?.sponsorName}</td>
                </tr>
                <tr>
                  <td>{t("regFee")}</td>
                  <td>
                    {userSelectedCurrency?.symbolLeft} {CurrencyConverter(props.preview?.regFee,conversionFactor)}
                  </td>
                </tr>

                {packageData && (
                  <>
                    <tr>
                      <td>{t("package")}</td>
                      <td>{packageData?.name}</td>
                    </tr>
                    <tr>
                      <td>{t("packageAmount")}</td>
                      <td>
                        {userSelectedCurrency?.symbolLeft} {CurrencyConverter(packageData?.price,conversionFactor)}
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <td>{t("totalAmount")}</td>
                  <td>
                    {userSelectedCurrency?.symbolLeft} {CurrencyConverter(props.preview?.totalAmount,conversionFactor)}
                  </td>
                </tr>
                
              </tbody>
            </table>
          </div>
          <div className="container-fluid">
            <div
              className="regardssec"
              style={{
                display: "inline-block",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <div
                className="regardBg"
                style={{
                  backgroundColor: "#e9f4f9",
                  borderRadius: "5px",
                  padding: "15px",
                  width: "100%",
                  height: "auto",
                }}
              >
                <p style={{ marginBottom: "5px" }}>
                <div dangerouslySetInnerHTML={{ __html: welcomeContentHtml }} />
                </p>
                <p>{t("wishingRegards")}</p>
                <p>{t("admin")}</p>
                <p>{companyData?.name}</p>
                <p style={{ marginBottom: "0" }}>{t("date")}</p>
                <p>{formatDate(date)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
});

