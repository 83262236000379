import React from "react";
// import TableContent from './TableContent';
import TablePagination from "../Common/table/TablePagination";
import { Link } from "react-router-dom";
import TableContent from "../Common/table/TableContent";

const TableMain = ({
  data,
  startPage,
  totalPages,
  pageDetails,
  setPageDetails,
  isLoading,
}) => {
  const header = [
    "#",
    "Ticket Id",
    "Subject",
    "Assignee",
    "Status",
    "Category",
    "Priority",
    "Created On",
    "Last Updated",
    "Timeline",
  ];
  const toNextPage = () => {
    if (pageDetails.currentPage < totalPages) {
      setPageDetails((prevPageDetails) => ({
        ...prevPageDetails,
        currentPage: prevPageDetails.currentPage + 1,
      }));
    }
  };

  const toLastPage = () => {
    setPageDetails((prevPageDetails) => ({
      ...prevPageDetails,
      currentPage: totalPages,
    }));
  };

  const toPreviousPage = () => {
    if (pageDetails.currentPage > startPage) {
      setPageDetails((prevPageDetails) => ({
        ...prevPageDetails,
        currentPage: prevPageDetails.currentPage - 1,
      }));
    }
  };

  const toFirstPage = () => {
    setPageDetails((prevPageDetails) => ({
      ...prevPageDetails,
      currentPage: startPage,
    }));
  };

  const handleItemsPerPageChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    setPageDetails((prevPageDetails) => ({
      ...prevPageDetails,
      currentPage: 1,
      itemsPerPage: selectedValue,
    }));
  };

  return (
    <>
      
      <TableContent
        headers={header}
        data={data?.Arr}
        type="tickets"
      />
      {data && data?.length !== 0 && (
        <TablePagination
          startPage={startPage}
          currentPage={pageDetails.currentPage}
          totalPages={totalPages!= 0 ? totalPages : 1 }
          itemsPerPage={pageDetails.itemsPerPage}
          toNextPage={toNextPage}
          toLastPage={toLastPage}
          toPreviousPage={toPreviousPage}
          toFirstPage={toFirstPage}
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
    </>
  );
};

export default TableMain;
