// const BASE_URL = "http://localhost:5000/";

// const DEFAULT_KEY = "fb48c168-e4aa-4ef3-8baf-c7ab89d98a1c";
// const DEFAULT_KEY = "fb48c168-e4aa-4ef3-8baf-c7ab89d98a1c";

// const BASE_URL = "http://162.19.146.135:1318/api";

// const BASE_URL = "http://192.168.23.47:5010/api";
// live
// const BASE_URL = "http://31.220.58.185:5000/";
const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";

//local
// const BASE_URL = "http://192.168.21.2:5065/api";
const BASE_URL = "https://ncmp.earnet.io/api";

const DOMAIN_URL='192.168.23.44:3000'

export { BASE_URL, DEFAULT_KEY ,DOMAIN_URL};
