import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";
import SponserTreeContent from "../../components/Tree/sponser-tree/SponserTreeContent";
import { useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { ApiHook } from "../../hooks/apiHook";
import { NavLink } from "react-router-dom";
import SubmitButton from "../../components/Common/buttons/SubmitButton";

const SponserTree = () => {
  const [selectedUserId, setSelectedUserId] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [doubleClickedUser, setDoubleClickedUser] = useState("");
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;
  const [username, setUsername] = useState("");
  const [selectedMenu, setSelectedMenu] = useState(false);
  

    ApiHook.CallSponsorTreeList("", "", searchUsername);
 
  const trimmedPathname = pathname.replace(/^\//, "");
  console.log("trimmedPathname",location.pathname)
  const plan = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus?.mlm_plan
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setUsername(value);
  };
  const handleSearch = () => {
    setSearchUsername(username);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // If Enter key is pressed, trigger the search
      handleSearch();
    }
  };
  const handleSideMenuToggle = () => {
    setSelectedMenu(!selectedMenu);
  };


  return (
    <>
     <div className="page_head_top">
        Level Earning
        <div className="right_btn_mob_toggle" onClick={handleSideMenuToggle}>
          <i className="fa fa-bars"></i>
        </div>
      </div>
      <div
        className={`tree_view_top_filter_bar mt-2 hide_show_mobile ${
          selectedMenu ? "show_mn" : ""
        }`}
      >
        <div className="row justify-content-between">
        <div
            className={`col-md-8 hide_show_mobile ${
              selectedMenu ? "show_mn" : ""
            }`}
          >
            <NavLink
              to="/level-income"
              className={({ isActive }) =>
                isActive ? "btn_ewallt_page active" : "btn_ewallt_page"
              }
            >
              Level Earning
            </NavLink>

            {plan !== "Unilevel" && (
              <NavLink
                to="/level-tree"
                className={({ isActive }) =>
                  isActive ? "btn_ewallt_page active" : "btn_ewallt_page"
                }
              >
                Level Earning Tree
              </NavLink>
            )}
          </div>
          {(trimmedPathname === "genealogy-tree" ||
            trimmedPathname === "sponsor-tree") && (
            <div className="col-md-4 mob_filter_right">
              <div className="right_search_div d-flex gap-1">
                <Form.Group>
                  <Form.Control
                    id="Search"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handleChange(e)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    value={username}
                    required
                  />
                </Form.Group>
                <SubmitButton
                  className="btn btn-primary"
                  text="Search"
                  click={handleSearch}
                />
              </div>
            </div>
          )}
        </div>
      </div>

   
      <SponserTreeContent
        selectedUserId={selectedUserId}
        setSelectedUserId={setSelectedUserId}
        doubleClickedUser={doubleClickedUser}
        setDoubleClickedUser={setDoubleClickedUser}
        searchUsername={searchUsername}
        setSearchUsername={setSearchUsername}
      />
    </>
  );
};

export default SponserTree;
