import AutopoolIncome from "../../components/Tree/AutopoolIncome";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useTranslation } from "react-i18next";

const AutoPool = () => {
  
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const [selectedMenu, setSelectedMenu] = useState(false);

  const plan = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus?.mlm_plan
  );

  const handleSideMenuToggle = () => {
    setSelectedMenu(!selectedMenu);
  };

  return (
    <>
      {/* <TreeViewNavbar menu={"downlineMembers"} />
      <div className="page_head_top">
        Autopool Income
        
      </div> */}
       <div className="page_head_top">
       AutoPool Earning
        <div className="right_btn_mob_toggle" onClick={handleSideMenuToggle}>
          <i className="fa fa-bars"></i>
        </div>
      </div>
      <div
        className={`tree_view_top_filter_bar mt-2 hide_show_mobile ${
          selectedMenu ? "show_mn" : ""
        }`}
      >
        <div className="row justify-content-between">
          <div
            className={`col-md-8 hide_show_mobile ${
              selectedMenu ? "show_mn" : ""
            }`}
          >
            {plan !== "Unilevel" && (
              <NavLink
                to="/autopool-tree"
                className={({ isActive }) =>
                  isActive ? "btn_ewallt_page active" : "btn_ewallt_page"
                }
              >
                 Autopool Tree
              </NavLink>
            )}
            <NavLink
              to="/autopool-income"
              className={({ isActive }) =>
                isActive ? "btn_ewallt_page active" : "btn_ewallt_page"
              }
            >
              AutoPool Referrals
            </NavLink>

            
          </div>
        </div>
      </div>

      <AutopoolIncome
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default AutoPool;
