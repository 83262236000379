import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import SubmitButton from "../../components/Common/buttons/SubmitButton";

const ReplicaSite = () => {
  const { t } = useTranslation();
  const replicaFlag = ApiHook.CallGetReplicaBanner();
  const Upload = ApiHook.CallUploadReplicaBanner(File)
  const [fileResponse, setFileResponse] = useState({
    success: null,
    error: null,
  });

  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    setFileResponse({
      success: null,
      error: null,
    });
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };
  const handleUpload = () => {
    if (file) {
      Upload.mutateAsync(file);
    }
  };

  return (
    <>
      <div className="page_head_top">Replica Site</div>
      <div className="uploadMainBg">
        <div className="currentBannerSec">
          <h3>Current Top Banner</h3>
          <img src={replicaFlag?.data?.image} alt="" />
        </div>
        <h3>Upload Top Banner</h3>
        <div className="uploadSubBg">
          <input
            type="file"
            placeholder=""
            className="form-control"
            name="fileUpload"
            onChange={handleFileChange}
          />
          {fileResponse?.success && (
            <div style={{ color: "green" }}>{t(fileResponse?.success)}</div>
          )}
          {fileResponse?.error && (
            <div style={{ color: "red" }}>{t(fileResponse?.error)}</div>
          )}
          <img src="images/upload.png" alt="" />
          <h6>Choose an image file or drag it here</h6>
          <p>please choose a Png/Jpeg/Jpg file</p>
          <p>Max size 2MB</p>
          <SubmitButton
            className="upload_btn"
            click={handleUpload}
            text={Upload?.isLoading ? "Uploading..." : t("upload")}
          />
        </div>
      </div>
    </>
  );
};

export default ReplicaSite;
