import React, { useRef } from "react";
import Selector from "./Selector";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Hourglass } from "react-loader-spinner";
import { toast } from "react-toastify";
import { BASE_URL } from "../../config/config";
// import API from '../../api/api'
import axios from "axios";
import { TicketService } from "../../services/ticket/ticket.js";


import { DEFAULT_KEY } from "../../config/config";

function CreateTicket() {

  const customAxios = axios.create({
    baseURL: BASE_URL,
  })
  customAxios.defaults.headers.common["api-key"] = DEFAULT_KEY;
  customAxios.defaults.headers.common["access-token"] = localStorage.getItem("access-token") || "";
  customAxios.defaults.headers.common["Content-Type"] = "multipart/form-data";

  const CateRef = useRef();
  const priRef = useRef();
  const messageRef = useRef();
  const attachRef = useRef();

  const notify = (message) => toast.error(`${message}`);

  const { data, error, isLoading } = useQuery({
    queryKey: ['ticket-filter'],
    queryFn: TicketService.callfilters,
  });

  const location = useLocation();

  const SubmitTicket=()=>{

    const formData = new FormData();

    if(attachRef.current.files.length > 0){
      let fileSize = 2 * 1024 * 1024;
      let actualsize=attachRef.current.files[0].size
      let file=attachRef.current.files[0]
      if(actualsize>fileSize){
        notify("file size exceeded!!")
        return
      }
      formData.append('file', file);
    }

    if(priRef.current.value=='')
    {
      notify('select priority')
      return
    }
    if(CateRef.current.value=='')
    {
      notify('select category')
      return
    }
    if(messageRef.current.value=='')
    {
      notify('message cannot be empty')
      return
    }
    

   

    formData.append('pid', priRef.current.value);
    formData.append('cid', CateRef.current.value);
    formData.append('message', messageRef.current.value);

  
 
    priRef.current.value=null
    CateRef.current.value=null
    messageRef.current.value=null
    attachRef.current.value=null

    console.log("pr",formData.get('pid') )
    console.log("pr",formData.get('cid') )
    console.log("pr",formData.get('message') )
    
  console.log(formData,"formData")
    customAxios.post(`tickets?type=ticket`,formData).then(res=>{
      if(res.status){
        toast.success("Ticket Created")
      }
    })
    .catch()
  }


  return (
    <>
      <Selector pathname={location.pathname.replace(/^\/+/g, "")} />
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            top: "50vh",
            left: "50vw",
          }}
        >
          <Hourglass
            visible={true}
            height="80"
            width="80"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["#306cce", "#72a1ed"]}
          />
        </div>
      ) : (
        <div class="support_cnt_box">
          <div class="row">
            <div class="col-md-6">
              <div class="create_ticket_row">
                <label for="">Priority *</label>
                <select ref={priRef} class="form-control">
                  {data.priority.map((i) => (
                    <option value={i.id}>{i.name}</option>
                  ))}
                </select>
              </div>
              <div class="create_ticket_row">
                <label for="">
                  Category <span>*</span>
                </label>
                <select class="form-control" ref={CateRef}>
                  {data.category.map((i) => (
                    <option value={i.id}>{i.name}</option>
                  ))}
                </select>
              </div>
              <div class="create_ticket_row">
                <label for="">
                  Message to Admin <span>*</span>
                </label>
                <textarea
                ref={messageRef}
                  class="form-control"
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                ></textarea>
              </div>
              <div class="create_ticket_row">
                <label for="">Attachment</label>
                <input type="file" ref={attachRef} class="form-control" />
              </div>
              <div class="col-md-12">
                <button
                  class="btn btn-primary float-end"
                  onClick={SubmitTicket}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CreateTicket;
