import React, { useState } from "react";
import anime from "animejs/lib/anime.es.js";
import { ApiHook } from "../../hooks/apiHook";
import faqImg from '../../assests/images/faq-img.jpg'
import cloudIMg from '../../assests/images/cloud.png'
const Nft = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const faqs = ApiHook.CallGetFaqs();
  const handleAccordionClick = (index) => {
    animateStepTransition(index);
    setActiveAccordion(index === activeAccordion ? null : index);
  };
  const animateStepTransition = (index) => {
    const containerElement = document.querySelector(`#animation${index}`);
    anime.set(containerElement, { opacity: 0, translateY: "10%" });
    anime({
      targets: containerElement,
      opacity: [0, 1],
      translateY: "-10%",
      duration: 500,
      easing: "easeInOutQuad",
    });
  };



  return (
    <>
      <div className="page_head_top">NFT</div>
      <div className="container">
        <section className="faq-sec">
          <div className="row">
            <div className="col-xl-12">
              <div className="faq-box">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <img src={faqImg} alt="" className="crypto-img" />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="box-con">
                      <div className="con">
                        <p>Congratulations on</p>
                        <span>purchasing your NFT</span>
                      </div>
                      <div className="neft-box">
                        <p>NFT Quantity </p>
                        <p><b>{faqs?.data?.nft ?? 0}</b></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="launch-box">
                  <img src={cloudIMg} alt="" className="cld-img" />
                  <p>NFT launch in Opensea Coming soon</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
  );
};

export default Nft;
